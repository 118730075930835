import { React, useEffect, useState } from "react";
import logo from "@/Images/maryLogo.png";
import landingPage from "@/Images/landingPage.jpg";
import lockScreenImage from "@/Images/lockScreenImage.png";
import willImg from "@/Images/willImg.jpg";
import { Link } from "react-router-dom";

const words = [
  "Strain",
  "Product",
  "Brand",
  "Breeder",
  "Grower",
  "Dispensary",
  "Social Club",
  "Seed Bank",
  "Grow Shop",
  "Doctor",
  "Event",
  "Article",
  "Artist",
  "People",
  "Deal",
];

const LandingPage = () => {
  return (
    <div className="px-2 md:px-4 pb-12 bg-[#0f0f0f] ">
      <div className="flex justify-between items-center text-white">
        <div className="text-white pt-1">
          <Link to="/">
            <img
              src={logo}
              alt="Ask Marry"
              className="w-24 h-13 md:h-16 lg:h-20 md:w-36"
            />
          </Link>
        </div>
        <div className="text-white space-x-2 md:space-x-4">
          <Link
            to={"/login"}
            className="text-white text-sm py-1 px-3 rounded-lg hover:bg-gray-700 border border-green-400 focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-opacity-50"
          >
            Dev login
          </Link>
          <button className="bg-[#62AF41] text-white text-sm py-1 px-2 rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50">
            Join waitlist
          </button>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center text-white px-4">
        <img
          src={lockScreenImage}
          alt="Ask Marry"
          className="w-75 h-75 md:w-120 md:h-120"
        />
      </div>

      <div className="flex justify-center items-center px-4 md:px-48">
        <img src={landingPage} alt="Mary" className="" />
      </div>

      <div className="px-4 py-4 md:px-48">
        <div className=" flex flex-col gap-3 bg-[#2c2c2c] text-sm text-white  rounded-3xl xl px-5 py-8">
          <p>
            In the past, purchasing cannabis was straightforward but limited:
            you bought what your local supplier had. Today, even though legal
            cannabis is accessible to many, the experience often lacks
            transparency and feels overwhelming, impersonal, and disconnected
            due to a complex regulatory environment distancing producers from
            consumers and a flood of mediocre products.
          </p>
          <p>
            Yet, despite these challenges, the cannabis community continues to
            thrive powered by businesses and individuals across the globe.
          </p>
          <p>
            To support them we created Mary, a better way to buy and sell weed.
          </p>
          <p>
            We’ve created a platform where consumers can directly connect with
            producers, discover products that meet their specific needs, and
            access tools to verify product authenticity, track usage, and
            understand their cannabis consumption better.
          </p>
          <p>
            Our business model supports producers in reaching a broader
            audience, connecting with customers, selling their products and
            memberships effectively, and leveraging insightful data to enhance
            their operations.
          </p>
          <p>
            We invite you to join us in creating a better way to buy and sell
            weed.
          </p>

          <div className=" flex justify-start items-center gap-2">
            <img
              src={willImg}
              alt="William"
              className="w-10 h-10 rounded-full"
            />

            <div>
              <h5 className=" font-bold">Will Oseroff</h5>
              <p>Founder</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const UnderDevelopmentPage = () => {
  // eslint-disable-next-line no-unused-vars
  const [currentWord, setCurrentWord] = useState(words[0]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((count) => (count + 1) % words.length);
    }, 4000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setCurrentWord(words[count]);
  }, [count]);

  return <LandingPage />;
};

export default UnderDevelopmentPage;
