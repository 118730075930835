import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { enqueueSnackbar } from 'notistack';


const environment = process.env.REACT_APP_ENV || "production";
const base_url = environment === 'production' ? process.env.REACT_APP_PRODUCTION_URL : process.env.REACT_APP_LOCAL_URL;

export const baseApi = createApi({
    reducerPath: 'baseApi',
    baseQuery: async (args, api, extraOptions) => {
        const baseQuery = fetchBaseQuery({
            // baseUrl: "http://192.168.1.4:5000/api/v1",
            baseUrl: base_url,

            prepareHeaders: (headers, { getState }) => {
                const token = getState().auth.jwt;

                if (token) {
                    headers.set('Authorization', `Bearer ${token}`);
                }
                return headers;
            },
        });

        const result = await baseQuery(args, api, extraOptions);

        // Global error handling
        if (result.error) {
            const { error } = result;

            // Trigger notistack error notification
            enqueueSnackbar(error?.data?.message || 'API request failed', { variant: 'error' });
            // Check if it's an HTML response (which might indicate a 404 or server error)
            if (error.status === 404 || error.originalStatus === 404) {
                enqueueSnackbar('Resource not found', { variant: 'error' });
            } else if (error.status === 500 || error.originalStatus === 500) {
                enqueueSnackbar('Server error, please try again later', { variant: 'error' });
            }
        }

        return result;
    },
    endpoints: () => ({}), // Define endpoints in other files
});
