import React, { useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FaCamera, FaCheckCircle, FaLink } from 'react-icons/fa';
import { CiCirclePlus } from 'react-icons/ci';
import StrainPopup from '../../components/StrainDetail/StrainPopup';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { addStrainToFolder } from '../../features/stashFolder/folderService';
import { IoShareOutline, IoTimer } from 'react-icons/io5';
import SocialShareModal from '../StrainDetail/SocialShare';
import FolderSelectionModal from '../StrainDetail/FolderSelectionModal';
import 'react-toastify/dist/ReactToastify.css';
import html2canvas from 'html2canvas';
import { getUrl } from '../../utils/GetBussinessUrl';
import { enqueueSnackbar } from 'notistack';

const Modal = ({ isOpen, onClose, children, title, productImg }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-opacity-75 flex justify-center items-center z-30">
      <div className="bg-[#2c2c2c] rounded-lg shadow-xl p-4 m-4 w-80 md:w-85 max-h-full overflow-auto">
        <div className="flex justify-between items-center">
          <div className="flex justify-start items-center space-x-2">
            <img
              src={productImg}
              alt={title}
              className="h-10 w-10 rounded-md"
              loading="lazy"
            />
            <h2 className="text-white text-md font-semibold">{title}</h2>
          </div>
          <button
            onClick={onClose}
            className="text-gray-600 hover:text-gray-900"
          >
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="flex justify-between items-center pt-4 px-8">
          <button
            type="button"
            className="text-sm font-semibold rounded-xl bg-[#d9d9d9] hover:bg-[#777777] border border-gray-500 px-3 py-1.5"
          >
            Add to cart
          </button>
          <button
            type="button"
            className="text-sm font-semibold rounded-xl bg-[#62AF41] border-[#62AF41] hover:bg-[#426f2b] px-3 py-1.5"
          >
            Buy it now
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

// You can add or remove properties as needed
const Products = ({
  itemId,
  itemType,
  title,
  productImg,
  price,
  category,
  weight,
  strainId,
  brand,
  onUpdate,
}) => {
  // hooks
  const navigate = useNavigate();

  // globalState
  const folders = useSelector((state) => state.folder.folders);

  // localState

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
  const cardRef = useRef();

  const isAddedInFolder = useMemo(() => {
    for (let folder of folders) {
      if (folder.products?.find((item) => item?._id === itemId)) {
        return true;
      }
    }
    return false;
  }, [itemId, folders]);

  const toggleFolderModal = () => {
    setIsFolderModalOpen(true);
  };

  const handleAddToStash = async () => {
    const likedStrainsFolder = folders?.find(
      (folder) => folder.name === 'Liked Products'
    );
    try {
      let Data = {
        itemId,
        type: itemType,
      };
      const res = await addStrainToFolder(likedStrainsFolder._id, Data);
      if (res) {
        onUpdate();
        enqueueSnackbar(res?.message, { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };

  const renderIcon = () => {
    if (isAddedInFolder) {
      return (
        <FaCheckCircle
          size={28}
          className="text-[#62AF41]"
          onClick={toggleFolderModal}
        />
      );
    } else {
      return (
        <CiCirclePlus
          size={28}
          className="text-white"
          onClick={handleAddToStash}
        />
      );
    }
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const handleNavigateToStrain = () => {
    toggleModal(); // Close the modal
    navigate(`/strain/${strainId}`); // Navigate to the strain detail page
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const shareUrl = `https://maryapp.io/product/${itemId}`;

  const handleDownloadImage = () => {
    setIsModalOpen(false);
    setTimeout(() => {
      if (cardRef.current === null) {
        return;
      }
      cardRef.current.style.backgroundColor = '#0f0f0f';
      document.fonts.ready.then(() => {
        html2canvas(cardRef.current, { useCORS: true })
          .then((canvas) => {
            cardRef.current.style.backgroundColor = '';
            myRenderFunction(canvas);
          })
          .catch((err) => {
            console.error('Oops, something went wrong!', err);
            cardRef.current.style.backgroundColor = '';
          });
      });
    }, 200);
  };

  function myRenderFunction(canvas) {
    var a = document.createElement('a');
    a.href = canvas
      .toDataURL('image/jpeg')
      .replace('image/jpeg', 'image/octet-stream');
    a.download = 'product.jpg';
    a.click();
  }

  return (
    <>
      <div className="w-full h-full " ref={cardRef}>
        <Link to={`/product/${itemId}/`}>
          <div className="pt-2 pb-2">
            <img
              src={productImg}
              alt={title}
              className="w-full h-40 rounded-xl md:h-54 object-cover"
              loading="lazy"
            />
          </div>
        </Link>
        <div
          onClick={() => navigate(`/product/${itemId}/`)}
          className="text-white text-xs whitespace-nowrap cursor-pointer"
        >
          {brand?.title}
        </div>
        <div className="flex justify-between items-center gap-2">
          <div
            onClick={() => navigate(`/product/${itemId}/`)}
            className="flex justify-start items-center text-white font-bold text-base whitespace-nowrap overflow-hidden text-ellipsis cursor-pointer"
          >
            {title}
          </div>
          <div className="flex justify-center items-center space-x-1">
            <span className="cursor-pointer">{renderIcon()}</span>
            {folders && (
              <FolderSelectionModal
                itemType="product"
                itemId={itemId}
                backBtnText="Back to Product"
                isOpen={isFolderModalOpen}
                onClose={() => setIsFolderModalOpen(false)}
                onUpdate={() => onUpdate()}
              />
            )}

            <span className="cursor-pointer">
              <BsThreeDotsVertical
                size={24}
                className="text-white"
                onClick={toggleModal}
              />
            </span>
            <Modal
              isOpen={isModalOpen}
              onClose={toggleModal}
              title={title}
              productImg={productImg}
            >
              <div className="flex flex-col justify-start items-start gap-2 pt-4">
                <div className="flex justify-start items-center space-x-2">
                  <span className="cursor-pointer">{renderIcon(itemId)}</span>
                  <span className="flex justify-start items-center text-gray-300">
                    Add to Stash
                  </span>
                </div>

                <div
                  onClick={() =>
                    navigate(getUrl(brand?.PrimaryVendor, brand?._id))
                  }
                  className="flex justify-start items-center space-x-2"
                >
                  <span className="cursor-pointer">
                    <FaLink size={28} className="rounded-full text-white" />
                  </span>
                  <span className="flex justify-start items-center text-gray-300">
                    Go to Brand
                  </span>
                </div>

                <div
                  onClick={handleNavigateToStrain}
                  className="flex justify-start items-center space-x-2"
                >
                  <span className="cursor-pointer">
                    <FaLink size={28} className="rounded-full text-white" />
                  </span>
                  <span className="flex justify-start items-center text-gray-300">
                    Go to Strain
                  </span>
                </div>

                <div className="flex justify-start items-center space-x-2">
                  <span
                    onClick={() => setShowShareOptions(true)}
                    className="cursor-pointer text-white"
                  >
                    {<IoShareOutline size={28} className="cursor-pointer" />}
                  </span>
                  <span className="flex justify-start items-center text-gray-300">
                    Share
                  </span>
                  {showShareOptions && (
                    <SocialShareModal
                      isOpen={showShareOptions}
                      url={shareUrl}
                      title={title}
                      strainImg={productImg}
                      onClose={() => setShowShareOptions(false)}
                      des={'Share this product'}
                    />
                  )}
                </div>
                <div className="flex justify-start items-center space-x-2">
                  <button
                    onClick={handleDownloadImage}
                    className="flex justify-start items-center"
                  >
                    <FaCamera size={24} className="text-white" />
                  </button>
                  <span className="flex justify-start items-center text-gray-300">
                    Download
                  </span>
                </div>
                <div
                  onClick={() =>
                    navigate(
                      `/sessions-review?type=Product&business=${brand?._id}&id=${itemId}`
                    )
                  }
                  className="flex justify-start items-center space-x-2  cursor-cell"
                >
                  <div className="flex justify-start items-center">
                    <IoTimer size={24} className="text-white" />
                  </div>
                  <span className="flex justify-start items-center text-gray-300">
                    Add session
                  </span>
                </div>
              </div>
            </Modal>
          </div>
        </div>
        <div
          onClick={() => navigate(`/product/${itemId}/`)}
          className="flex justify-start items-start text-white cursor-pointer"
        >
          <div className="text-white text-xs">
            {weight?.value} {weight?.unit}
          </div>
          <div className="flex-none self-center px-1 text-xs">•</div>
          <div className="text-white text-xs">${price}</div>
        </div>
        <div
          onClick={() => navigate(`/product/${itemId}/`)}
          className="text-white text-xs whitespace-nowrap cursor-pointer"
        >
          {category}
        </div>
      </div>
      {isPopupOpen && <StrainPopup isOpen={isPopupOpen} onClose={closePopup} />}
    </>
  );
};

export default Products;
