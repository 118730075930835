import axios from 'axios';
import { config } from '../../utils/axiosconfig';
import { base_url } from '../../utils/base_url';


export const getSessionBrands = async () => {
    const configure = config()
    const response = await axios.get(`${base_url}sessions/get-session-brands`, configure);
    return response.data;

};


export const getSessionProducts = async (brandId) => {

    const configure = config()
    const response = await axios.get(`${base_url}sessions/get-session-products?brandId=${brandId}`, configure);
    return response.data;

};

export const getSessionStrains = async () => {
    const configure = config()
    const response = await axios.get(`${base_url}sessions/get-session-strains`, configure);
    return response.data;
};

export const createSession = async (sessionData) => {
    const configure = config()
    const response = await axios.post(`${base_url}sessions`, sessionData, configure);
    return response.data;
};


export const deleteSession = async (sessionId) => {
    const configure = config()
    const response = await axios.delete(`${base_url}sessions/${sessionId}`, configure);
    return response.data;
};

export const deleteUserItem = async (item) => {
    const configure = config(); // assuming config() returns an object with headers, etc.

    const response = await axios.delete(`${base_url}userData/delete-data`, {
        headers: configure.headers, // include headers
        data: item, // include the item data
    });

    return response.data;
};

export const updateUserItem = async (data) => {
    const configure = config()
    const response = await axios.put(`${base_url}userData/update-data`, data, configure);
    return response.data;
};

export const getSession = async () => {
    const configure = config()
    const response = await axios.get(`${base_url}sessions`, configure);
    return response.data;
};


export const setGoal = async (data) => {
    const configure = config()
    const response = await axios.post(`${base_url}sessions/set-goal`, data, configure);
    return response.data;
};

export const getGoal = async () => {
    const configure = config()
    const response = await axios.get(`${base_url}sessions/set-goal`, configure);
    return response.data;
};


// Add Product from user which is not avaialbe in our record
export const addCustomProduct = async (data) => {
    const configure = config()
    const response = await axios.post(`${base_url}user-item`, data, configure);
    return response.data;
};
// Add Product from user which is not avaialbe in our record
export const getCustomProduct = async () => {
    const configure = config()
    const response = await axios.get(`${base_url}user-item`, configure);
    return response.data;
};

// Add Product from user which is not avaialbe in our record
export const getStats = async (selectedPeriod) => {
    const configure = config()
    const response = await axios.get(`${base_url}stats?searchBy=${selectedPeriod}`, configure);
    return response.data;
};


// add gaol to db
export const addSessionGoalToDB = async (data) => {
    const configure = config()
    const response = await axios.post(`${base_url}sessions/set-goal`, data, configure);
    return response.data;
};


export const getSessionGoalToDB = async () => {
    const configure = config()
    const response = await axios.get(`${base_url}sessions/get-goals`, configure);
    return response.data;
};









