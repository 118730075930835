import axios from 'axios';
import { config } from '../../utils/axiosconfig';
import { base_url } from '../../utils/base_url';

// Create Folder
export const createFolder = async (data) => {
    const configure = config();
    const response = await axios.post(`${base_url}folder/`, data, configure);
    return response.data;
};

// Update Folder
export const updateFolder = async (id, folderData) => {
    const configure = config();
    const response = await axios.put(`${base_url}folder/${id}`, folderData, configure);
    return response.data;
};

// Delete Folder
export const deleteFolder = async (id) => {
    const configure = config();
    const response = await axios.delete(`${base_url}folder/${id}`, configure);
    return response.data;
};

// Get All Folders
export const getAllFolders = async () => {
    const configure = config();
    const response = await axios.get(`${base_url}folder/`, configure);
    return response.data;
};

export const getAllFoldersForSession = async () => {
    const configure = config();
    const response = await axios.get(`${base_url}folder/sessionFolders`, configure);
    return response.data;
};

// Get Single Folder
export const getFolder = async (id) => {
    const response = await axios.get(`${base_url}folder/${id}`);
    return response.data;
};
export const addStrainToFolder = async (folderId, Data) => {
    const configure = config();
    const response = await axios.put(`${base_url}folder/${folderId}/addStrain`, Data, configure);
    return response.data;
};

export const removeStrainFromFolder = async (folderId, Data) => {
    const configure = config();
    const response = await axios.put(`${base_url}folder/${folderId}/removeStrain`, Data, configure);
    return response.data;
};


// Folder Service Exports
const folderService = {
    createFolder,
    updateFolder,
    deleteFolder,
    getAllFolders,
    getFolder,
    addStrainToFolder,
    removeStrainFromFolder,
    getAllFoldersForSession
};

export default folderService
