import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllFolders } from '../features/stashFolder/folderSlice';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { IoShareOutline } from 'react-icons/io5';
import { FaLock, FaLockOpen } from 'react-icons/fa';
import Products from '../components/products/Products';
import SocialShareModal from '../components/StrainDetail/SocialShare';
import FolderMenuModal from '../components/stash/FolderMenuModal';
import EditFolderModal from '../components/stash/EditFolderModal';
import {
  deleteFolder,
  updateFolder,
} from '../features/stashFolder/folderService';
import { enqueueSnackbar } from 'notistack';
import StashSinglePageLaoder from '../skeleton/StashSinglePageLaoder';
import { useGetSingleFolderQuery } from '../redux/api/folderApi';
import StrainCard from '@/common-components/strain/StrainCard';

const StashPages = () => {
  // hooks
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // globalState
  const userState = useSelector((state) => state.auth.user);

  // localState
  const [isUpdate, setIsUpdate] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNewModalOpen, setIsNewModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);

  const { data, error, isLoading } = useGetSingleFolderQuery(id);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error?.message || 'Error fetching data', {
        variant: 'error',
      });
    }
  }, [error]);

  const shareUrl = `https://maryapp.io/stash-details/`;

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const openActionModal = (folder) => {
    setIsNewModalOpen(!isNewModalOpen);
    onVisibilityChange(folder.visibility);
  };

  const handleEditFolder = (folder) => {
    setEditModalOpen(true);
  };

  const onVisibilityChange = (newVisibility) => {
    setIsUpdate(!isUpdate);
  };

  const submitEditFolder = async (updatedFolder) => {
    const folderData = {
      name: updatedFolder.name,
      description: updatedFolder.description,
    };
    try {
      const res = await updateFolder(updatedFolder._id, folderData);
      if (res) {
        setEditModalOpen(false);
        setIsUpdate(!isUpdate);
        enqueueSnackbar(res?.message, { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };

  const handleDeleteFolder = async (folderId) => {
    try {
      const res = await deleteFolder(folderId);
      if (res) {
        enqueueSnackbar(res?.message, { variant: 'success' });
        navigate('/stash');
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    let isSubscribe = true;
    if (isSubscribe) {
      dispatch(getAllFolders());
    }
    return () => (isSubscribe = false);
  }, [dispatch, isUpdate]);

  return (
    <div className="flex flex-col justify-start items-center w-full h-full py-1 px-4 md:px-12">
      {isLoading && <StashSinglePageLaoder />}
      {!isLoading && (
        <>
          <div className="flex justify-between items-center w-full">
            <div className="flex flex-col justify-start items-start pb-2">
              <div className="text-2xl font-bold text-white">{data?.name}</div>
              <div className="text-xs text-gray-500 capitalize pt-1">
                {data?.description}
              </div>
            </div>
            <div className="flex justify-start items-center">
              <IoShareOutline
                size={28}
                onClick={toggleModal}
                className="cursor-pointer text-white"
              />
              <BsThreeDotsVertical
                size={28}
                className="text-white"
                onClick={() => openActionModal(data)}
              />
            </div>
          </div>
          {isNewModalOpen && (
            <FolderMenuModal
              data={data}
              isOpen={isNewModalOpen}
              onClose={() => setIsNewModalOpen(false)}
              folder={data}
              onEdit={handleEditFolder}
              onDelete={handleDeleteFolder}
              onVisibilityChange={onVisibilityChange}
              userState={userState}
            />
          )}
          {isModalOpen && (
            <SocialShareModal
              isOpen={isModalOpen}
              url={`${shareUrl}${data?._id}`}
              title={data.name}
              strainImg={userState.userImg?.url}
              type={data.description}
              onClose={toggleModal}
              des={'Share this folder'}
            />
          )}

          {editModalOpen && (
            <EditFolderModal
              isOpen={editModalOpen}
              onClose={() => setEditModalOpen(false)}
              folder={data}
              editFolder={submitEditFolder}
            />
          )}

          {/* Name Row */}
          <div className="flex justify-start items-center w-full gap-2">
            <div>
              <img
                src={userState.userImg?.url}
                alt="FolderImage"
                className="w-6 h-6 rounded-full"
              />
            </div>
            <div className="text-white capitalize">
              {userState.firstname} {userState.lastname}
            </div>
          </div>

          {/* Icon Row */}
          <div className="flex justify-start items-center w-full text-gray-500 gap-2 px-1 py-2">
            <div>
              {data?.visibility === 'private' ? <FaLock /> : <FaLockOpen />}
            </div>
            <div className="text-xs">
              {data?.products?.length + data?.strains?.length} items
            </div>
          </div>
          {data?.products?.length > 0 && (
            <div className=" w-full">
              <h1 className="text-xl font-bold text-white py-2">Products</h1>
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-10 gap-4 w-full ">
                {data?.products?.map((product) => (
                  <Products
                    key={product._id}
                    itemId={product._id}
                    itemType="product"
                    title={product.title}
                    productImg={product.productImg.url}
                    price={product.price}
                    category={product.category}
                    brandId={product.brand}
                    weight={product.weight}
                    strainId={product.strain}
                    brand={product.brand}
                    onUpdate={() => setIsUpdate(!isUpdate)}
                  />
                ))}
              </div>
            </div>
          )}

          {/* Strains Row */}
          {data?.strains?.length > 0 && (
            <div className="flex flex-col items-start w-full pb-20 md:pt-2 mx-auto">
              <h1 className="text-xl font-bold text-white py-2">Strains</h1>
              <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4">
                {data?.strains?.map((strain) => (
                  <StrainCard
                    itemType="strain"
                    key={strain._id}
                    id={strain._id}
                    title={strain?.title}
                    father={strain?.father?.title ?? 'Not Found'}
                    mother={strain?.mother?.title ?? 'Not Found'}
                    type={strain?.type}
                    imageUrl={strain.strainImg.url}
                    business={strain?.business?.id}
                    onUpdate={() => setIsUpdate(!isUpdate)}
                  />
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default StashPages;
