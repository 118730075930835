import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import AllStrains from "./pages/AllStrains";
import StrainPages from "./pages/StrainPages";
import ReviewDetail from "./components/StrainDetail/ReviewDetail";
import ReviewProductDetail from "./components/productDetails/ReviewDetail";
import AllBreeder from "./pages/AllBreeder";
import Allbrand from "./pages/AllBrand";
import AllGrower from "./pages/AllGrower";
import AllDispensary from "./pages/AllDispensary";
import AllSocialClub from "./pages/AllSocialClub";
import AllHashMaker from "./pages/AllHashMaker";
import AllDelivery from "./pages/AllDelivery";
import AllSeedBank from "./pages/AllSeedBank";
import AllHempShop from "./pages/AllHempShop";
import AllGrowShop from "./pages/AllGrowShop";
import AllProduct from "./pages/AllProduct";
import ProductCategoryBrand from "./pages/ProductCategoryBrand";
import People from "./pages/People";
import Shop from "./pages/Shop";
import AddToCart from "./pages/AddToCart";
import StashPages from "./pages/StashPages";
import Notifications from "./pages/Notifications";
import Profile from "./pages/Profile";
import Subscription from "./pages/Subscription";
import SessionsReview from "./pages/SessionsReview";
import SearchProvider from "./components/context/searchContext";
import Search from "./pages/Search";
import PageNotFound from "./pages/PageNotFound";
import Discover from "./pages/Discover";
import Trending from "./pages/Trending";
import Doctor from "./pages/Doctor";
import Articles from "./pages/Articles";
import Artist from "./pages/Artist";
import Event from "./pages/Event";
import Deal from "./pages/Deal";
import FeedBack from "./pages/FeedBack";
import ForYou from "./pages/ForYou";
import AddToStash from "./pages/AddToStash";
import AllSubBrand from "./pages/AllSubBrand";
import Sessions from "./pages/Sessions";
import SessionsState from "./pages/SessionsState";
import Conversations from "./pages/Conversations";
import Join from "./pages/Join";
import BusinessDetailsPage from "@/components/CommonComponent/AddBusinesses/BusinessDetailsPage";
import ProductPage from "@/pages/ProductPages";
import Home from "@/pages/Home";
import MaryAiSearch from "@/pages/MaryAiSearch";
import UnderDevelopmentPage from "@/pages/UnderDevelopmentPage";

// SuperTokens
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
// import { PasswordlessPreBuiltUI } from "supertokens-auth-react/recipe/passwordless/prebuiltui";
import { EmailPasswordPreBuiltUI } from "supertokens-auth-react/recipe/emailpassword/prebuiltui";
import * as reactRouterDom from "react-router-dom";
import ProtectedRoute from "./components/protected-route";

export default function AppRoutes() {
  return (
    <BrowserRouter basename={process.env.APP_NAME}>
      <SearchProvider>
        <Routes>
          <Route path="/landing" element={<UnderDevelopmentPage />} />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Layout />
              </ProtectedRoute>
            }
          >
            <Route
              index
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route
              path="/strains"
              element={
                <ProtectedRoute>
                  <AllStrains />
                </ProtectedRoute>
              }
            />
            <Route
              path="/strain/:id"
              element={
                <ProtectedRoute>
                  <StrainPages />
                </ProtectedRoute>
              }
            />
            <Route
              path="/product/:id"
              element={
                <ProtectedRoute>
                  <ProductPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/strain-detail/:id/review-detail"
              element={
                <ProtectedRoute>
                  <ReviewDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="/product-detail/:id/review-detail"
              element={
                <ProtectedRoute>
                  <ReviewProductDetail />
                </ProtectedRoute>
              }
            />
            {[
              "/brand-detail/:id/:businessType",
              "/breeder-detail/:id/:businessType",
              "/grower-detail/:id/:businessType",
              "/dispensary-detail/:id/:businessType",
              "/socialClub-detail/:id/:businessType",
              "/hashMaker-detail/:id/:businessType",
              "/delivery-detail/:id/:businessType",
              "/seedBank-detail/:id/:businessType",
              "/hempShop-detail/:id/:businessType",
              "/growShop-detail/:id/:businessType",
            ].map((path) => (
              <Route
                key={path}
                path={path}
                element={
                  <ProtectedRoute>
                    <BusinessDetailsPage />
                  </ProtectedRoute>
                }
              />
            ))}
            <Route
              path="/Breeder"
              element={
                <ProtectedRoute>
                  <AllBreeder />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Brand"
              element={
                <ProtectedRoute>
                  <Allbrand />
                </ProtectedRoute>
              }
            />
            <Route
              path="/people"
              element={
                <ProtectedRoute>
                  <People />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Shop"
              element={
                <ProtectedRoute>
                  <Shop />
                </ProtectedRoute>
              }
            />
            <Route
              path="/cart"
              element={
                <ProtectedRoute>
                  <AddToCart />
                </ProtectedRoute>
              }
            />
            <Route
              path="/stash"
              element={
                <ProtectedRoute>
                  <AddToStash />
                </ProtectedRoute>
              }
            />
            <Route
              path="/allSubscribedBrand"
              element={
                <ProtectedRoute>
                  <AllSubBrand />
                </ProtectedRoute>
              }
            />
            <Route
              path="/search"
              element={
                <ProtectedRoute>
                  <Search />
                </ProtectedRoute>
              }
            />
            <Route
              path="/ai-search"
              element={
                <ProtectedRoute>
                  <MaryAiSearch />
                </ProtectedRoute>
              }
            />

            <Route
              path="/discover"
              element={
                <ProtectedRoute>
                  <Discover />
                </ProtectedRoute>
              }
            />
            <Route
              path="/foryou"
              element={
                <ProtectedRoute>
                  <ForYou />
                </ProtectedRoute>
              }
            />
            <Route
              path="/trending"
              element={
                <ProtectedRoute>
                  <Trending />
                </ProtectedRoute>
              }
            />
            <Route
              path="/products"
              element={
                <ProtectedRoute>
                  <AllProduct />
                </ProtectedRoute>
              }
            />
            <Route
              path="/products/:brandId/:categoryName"
              element={
                <ProtectedRoute>
                  <ProductCategoryBrand />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Grower"
              element={
                <ProtectedRoute>
                  <AllGrower />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Dispensary"
              element={
                <ProtectedRoute>
                  <AllDispensary />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Socialclub"
              element={
                <ProtectedRoute>
                  <AllSocialClub />
                </ProtectedRoute>
              }
            />
            <Route
              path="/HashMaker"
              element={
                <ProtectedRoute>
                  <AllHashMaker />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Delivery"
              element={
                <ProtectedRoute>
                  <AllDelivery />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Seedbank"
              element={
                <ProtectedRoute>
                  <AllSeedBank />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Hempshop"
              element={
                <ProtectedRoute>
                  <AllHempShop />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Growshops"
              element={
                <ProtectedRoute>
                  <AllGrowShop />
                </ProtectedRoute>
              }
            />
            <Route
              path="/doctors"
              element={
                <ProtectedRoute>
                  <Doctor />
                </ProtectedRoute>
              }
            />
            <Route
              path="/events"
              element={
                <ProtectedRoute>
                  <Event />
                </ProtectedRoute>
              }
            />
            <Route
              path="/articles"
              element={
                <ProtectedRoute>
                  <Articles />
                </ProtectedRoute>
              }
            />
            <Route
              path="/artists"
              element={
                <ProtectedRoute>
                  <Artist />
                </ProtectedRoute>
              }
            />
            <Route
              path="/deals"
              element={
                <ProtectedRoute>
                  <Deal />
                </ProtectedRoute>
              }
            />
            <Route
              path="/feedback"
              element={
                <ProtectedRoute>
                  <FeedBack />
                </ProtectedRoute>
              }
            />
            <Route
              path="/stash-details/:id"
              element={
                <ProtectedRoute>
                  <StashPages />
                </ProtectedRoute>
              }
            />
            <Route
              path="/notifications"
              element={
                <ProtectedRoute>
                  <Notifications />
                </ProtectedRoute>
              }
            />
            <Route
              path="/sessions"
              element={
                <ProtectedRoute>
                  <Sessions />
                </ProtectedRoute>
              }
            />
            <Route
              path="/sessions-state"
              element={
                <ProtectedRoute>
                  <SessionsState />
                </ProtectedRoute>
              }
            />
            <Route
              path="/answers"
              element={
                <ProtectedRoute>
                  <Conversations />
                </ProtectedRoute>
              }
            />
            <Route
              path="/join/:id/:businessType"
              element={
                <ProtectedRoute>
                  <Join />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/subscription"
              element={
                <ProtectedRoute>
                  <Subscription />
                </ProtectedRoute>
              }
            />
            <Route
              path="/sessions-review"
              element={
                <ProtectedRoute>
                  <SessionsReview />
                </ProtectedRoute>
              }
            />
          </Route>
          {getSuperTokensRoutesForReactRouterDom(reactRouterDom, [
            EmailPasswordPreBuiltUI,
          ])}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </SearchProvider>
    </BrowserRouter>
  );
}
