import { AccordionSection } from '../components/StrainReviews/AccordionSection';
import {
  CheckboxItem,
  EffectItem,
  FlavourItem,
} from '../components/StrainReviews/Effects';
import { IoArrowBack } from 'react-icons/io5';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  createSession,
  deleteUserItem,
  getCustomProduct,
  getSessionBrands,
  getSessionProducts,
  getSessionStrains,
  updateUserItem,
} from '../features/sessions/SessionServices';
import { FaPlus, FaUserEdit } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Select from '../components/CommonComponent/Select';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  conditionsList,
  effectsList,
  flavorsList,
} from '../components/CommonComponent/CommonData/CommonData';

import {
  endSession,
  setLoading,
  startSession,
} from '../features/sessions/sessionsSlice';
import AddCustomProductModal from '../components/Sessions/AddCustomProductModal';
import { format } from 'date-fns';
import CustomMultiRangeSlider from '../components/CommonComponent/multiRangeSlider/CustomMultiRangeSlider';
import { getUserDataThunk } from '../features/userAddedData/userAddedData';
import { AddUserData } from '../features/userAddedData/userAddedDataService';

const SessionsReview = () => {
  const { id } = '64bfc5e0fb7066e858effdbf';
  // Hooks
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const userAdded = searchParams.get('userAdded');
  const checkItemType = searchParams.get('type');
  const checkItemId = searchParams.get('id');
  const checkBusinessId = searchParams.get('business');

  // global State

  const folder = useSelector((state) => state?.folder?.folders);
  const { session, loading } = useSelector((state) => state?.sessions);
  const userAddedData = useSelector((state) => state?.userAddedData);

  const [allStrain, setAllStrain] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [allBrands, setAllBrands] = useState([]);

  // localState
  const [method, setMethod] = useState([
    ...(userAddedData?.userMethods || []),
    'Flower',
    'Concentrate',
    'Edible',
    'Topical',
    'Dab',
    'Tincture',
    'Beverage',
  ]);
  // Options for 'Consumed with'
  const consumedWithOptions = [
    ...(userAddedData?.userConsumingWith || []),
    'By Myself',
    'Friends',
    'Co-Workers',
    'Family',
    'Pet',
  ];
  // State for 'Places of Consumption' options
  const [addNewPlace, setAddNewPlace] = useState('');

  // Default options for 'Places of Consumption'
  const placesOptions = [
    ...(userAddedData?.userConsumingPlace || []),
    'Home',
    'Work',
    'Outside',
    'Commuting',
  ];

  const [addNewConsumedWith, setAddNewConsumedWith] = useState('');
  const [sessionStarted, setSessionStarted] = useState(
    session?.sessionStartTime ? true : false
  );
  const [timer, setTimer] = useState('00:00:00');
  const [isUpdate, setisUpdate] = useState(false);

  const [addMethod, setaddMethod] = useState('');
  const [addNewReason, setAddNewReason] = useState('');
  const [selectedEffects, setSelectedEffects] = useState([]);
  const [flavorAndSmell, setFlavorAndSmell] = useState([]);
  const [isExpandedFlavor, setIsExpandedFlavor] = useState(false);
  const [isExpandedHelpWith, setIsExpandedHelpWith] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [displayItemCount, setDisplayItemCount] = useState(getDisplayCount());
  const [fillterFolder, setFillterFolder] = useState([]);
  const [customEffectInput, setCustomEffectInput] = useState('');
  const [customFlavorInput, setCustomFlavorInput] = useState('');
  const [userAddedItem, setuserAddedItem] = useState([]);
  const [Data, setData] = useState({
    reviewType: session?.reviewType ?? 'Public',
    selectedItem: checkItemType ? checkItemType : session?.selectedItem ?? '',
    selectedFolder: session?.selectedFolder ?? '',
    strain: checkItemId ? checkItemId : session?.strain ?? '',
    selectedProduct: checkItemId ? checkItemId : session?.selectedProduct ?? '',
    selectedBussines: checkBusinessId
      ? checkBusinessId
      : session?.selectedBussines ?? '',
    intendedUse: session?.intendedUse ?? 0,
    selectedMethod: session?.selectedMethod ?? [],
    wasEffected: session?.wasEffected ?? 0,
    intensityAfter: session?.intensityAfter ?? 0,
    additionalThoughts: session?.additionalThoughts ?? '',
    consumingUnit: session?.consumingUnit ?? 'Gram',
    consumingQty: session?.consumingQty ?? '',
    sessionStartTime: session?.sessionStartTime ?? '',
    sessionEndTime: session?.sessionEndTime ?? '',
    effects: session?.effects ?? [],
    flavors: session?.flavors ?? [],
    useReason: session?.useReason ?? [],
    userAdded: session?.userAdded ?? '',
    consumingPlace: session?.consumingPlace ?? '',
    consumingWith: session?.consumingWith ?? '',
  });

  const [oldValue, setOldValue] = useState('');

  const currentStrain = allStrain.find((state) => state._id === id);

  const getAllStrain = useCallback(async () => {
    try {
      const res = await getSessionStrains();
      if (res) {
        setAllStrain(res);
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  }, [enqueueSnackbar]);

  const getAllBrands = useCallback(async () => {
    try {
      const res = await getSessionBrands();
      if (res) {
        setAllBrands(res?.brands);
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  }, [enqueueSnackbar]);

  const getAllProducts = useCallback(
    async (brandId) => {
      try {
        const res = await getSessionProducts(brandId);
        if (res) {
          setAllProducts(res);
        }
      } catch (error) {
        setAllProducts([]);
        enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      }
    },
    [enqueueSnackbar]
  );

  useEffect(() => {
    let isSubscribe = true;
    if (isSubscribe) {
      getAllStrain();
      getAllBrands();
      if (Data?.selectedBussines) {
        getAllProducts(Data?.selectedBussines);
      }
    }

    return () => (isSubscribe = false);
  }, [
    Data.selectedItem,
    Data?.selectedBussines,
    getAllBrands,
    getAllProducts,
    getAllStrain,
  ]);

  const userAddedProduct = useCallback(async () => {
    const res = await getCustomProduct();
    setuserAddedItem(res);
    return res;
  }, []);

  const handleAddCustomEffect = async () => {
    if (customEffectInput === '') return;
    try {
      const res = await AddUserData({
        userFeelings: customEffectInput.replace(
          /^./,
          customEffectInput[0]?.toUpperCase()
        ),
      });
      if (res?.status === 200) {
        setCustomEffectInput('');
        setisUpdate((prev) => !prev); // Toggle the state to trigger updates
        enqueueSnackbar(res?.data?.message, { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };

  const handleAddCustomFlavor = async () => {
    if (customFlavorInput === '') return;
    try {
      const res = await AddUserData({
        userFlavours: customFlavorInput.replace(
          /^./,
          customFlavorInput[0]?.toUpperCase()
        ),
      });
      if (res?.status === 200) {
        setCustomFlavorInput('');
        setisUpdate((prev) => !prev); // Toggle the state to trigger updates
        enqueueSnackbar(res?.data?.message, { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };

  // Function to add a new 'Consumed with' option
  const addConsumedWithOption = async () => {
    try {
      const res = await AddUserData({
        userConsumingWith: addNewConsumedWith?.replace(
          /^./,
          addNewConsumedWith[0]?.toUpperCase()
        ),
      });
      if (res?.status === 200) {
        setisUpdate(!isUpdate);
        setAddNewConsumedWith('');
        enqueueSnackbar(res?.data?.message, { variant: 'success' });
      }
      if (res?.response?.status === 400) {
        enqueueSnackbar(res?.response?.data?.message, { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };

  // Function to handle checkbox changes for 'Places of Consumption'
  const handlePlaceChange = (place) => {
    if (Data.consumingPlace?.includes(place)) {
      let deleteItem = Data.consumingPlace.filter((item) => item !== place);
      setData({
        ...Data,
        consumingPlace: [...deleteItem],
      });
    } else {
      setData({
        ...Data,
        consumingPlace: [...Data.consumingPlace, place],
      });
    }
  };

  // Function to add a new 'Place of Consumption'
  const addPlaceOfConsumption = async () => {
    try {
      const res = await AddUserData({
        userConsumingPlace: addNewPlace?.replace(
          /^./,
          addNewPlace[0]?.toUpperCase()
        ),
      });
      if (res?.status === 200) {
        setisUpdate(!isUpdate);
        setAddNewPlace('');
        enqueueSnackbar(res?.data?.message, { variant: 'success' });
      }
      if (res?.response?.status === 400) {
        enqueueSnackbar(res?.response?.data?.message, { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };

  // Function to handle the method check boxes
  const handleMethodChange = (newMethod) => {
    if (Data.selectedMethod.includes(newMethod)) {
      let deleteItem = Data.selectedMethod.filter((item) => item !== newMethod);
      setData({
        ...Data,
        selectedMethod: [...deleteItem],
      });
    } else {
      setData({ ...Data, selectedMethod: [...Data.selectedMethod, newMethod] });
    }
  };

  const handleConsumedWith = (consumWith) => {
    if (Data.consumingWith?.includes(consumWith)) {
      let deleteItem = Data.consumingWith.filter((item) => item !== consumWith);
      setData({
        ...Data,
        consumingWith: [...deleteItem],
      });
    } else {
      setData({
        ...Data,
        consumingWith: [...Data.consumingWith, consumWith],
      });
    }
  };

  // Handler for effect selection
  const handleSelectEffect = (effect) => {
    let updatedSelectedEffects;
    if (selectedEffects.includes(effect)) {
      updatedSelectedEffects = selectedEffects.filter((e) => e !== effect);
    } else if (selectedEffects.length < 4) {
      updatedSelectedEffects = [...selectedEffects, effect];
    } else {
      // If we already have 4 effects, we don't add more
      return;
    }
    setSelectedEffects(updatedSelectedEffects);
    setData({ ...Data, effects: updatedSelectedEffects });
  };

  // Handler for flavour selection
  const handleSelectFlavor = (flavor) => {
    let updatedSelectedFlavors;
    if (flavorAndSmell.includes(flavor)) {
      updatedSelectedFlavors = flavorAndSmell.filter((e) => e !== flavor);
    } else if (flavorAndSmell.length < 4) {
      updatedSelectedFlavors = [...flavorAndSmell, flavor];
    } else {
      // If we already have 4 effects, we don't add more
      return;
    }
    setFlavorAndSmell(updatedSelectedFlavors);
    // Update reviewData state as well if needed
    setData({ ...Data, flavors: updatedSelectedFlavors });
  };

  // Toggle function for expand/collapse for Flavor
  const toggleExpandForFlavor = () => {
    setIsExpandedFlavor(!isExpandedFlavor);
  };

  // Toggle function for expand/collapse for Helps With
  const toggleExpandForHelpsWith = () => {
    setIsExpandedHelpWith(!isExpandedHelpWith);
  };

  function getDisplayCount() {
    return window.innerWidth >= 1024 ? 21 : 7;
  }
  const handleProductAdded = async () => {
    try {
      const addedProducts = await getCustomProduct();
      setuserAddedItem(addedProducts); // Assume the latest added product is first in the list
      setData((prev) => ({
        ...prev,
        selectedItem: 'UserAdded',
        selectedProduct: addedProducts[0]?._id,
        userAdded: addedProducts[0]?._id,
      }));
      setSearchParams({ userAdded: true }); // Optionally control UI or other effects
    } catch (error) {
      console.error('Failed to fetch products:', error);
    }
  };

  useEffect(() => {
    if (userAdded === true) {
      handleProductAdded();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAdded]);

  const getUserData = useCallback(() => {
    dispatch(getUserDataThunk());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, Data?.selectedMethod, method]);

  // Only call getUserData when specific dependencies change
  useEffect(() => {
    getUserData();
  }, [getUserData, isUpdate]);

  // Define effects list Here
  useEffect(() => {
    let isSubscribe = true;
    if (isSubscribe) {
      userAddedProduct();
    }
    return () => {
      isSubscribe = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, Data.selectedProduct, Data.selectedItem, Data?.selectedMethod]);

  const DataUpdate = useCallback(() => {
    dispatch(startSession({ ...Data }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Data]);

  useEffect(() => {
    DataUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditionsList, Data]);

  useEffect(() => {
    let interval;

    if (session?.sessionStartTime) {
      const startTimer = () => {
        const startTime = new Date(session.sessionStartTime).getTime();
        interval = setInterval(() => {
          const now = Date.now();
          const difference = now - startTime;
          const hours = Math.floor(
            (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor(
            (difference % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor((difference % (1000 * 60)) / 1000);
          setTimer(`${pad(hours)}:${pad(minutes)}:${pad(seconds)}`);
        }, 1000);
      };

      startTimer();
    }

    return () => clearInterval(interval);
  }, [session?.sessionStartTime]);

  const pad = (num) => num.toString().padStart(2, '0');

  // Correcting potential typo and fixing the dependency array
  const folderOptions = useMemo(() => {
    const combinedArray =
      fillterFolder[0]?.products.length > 0 ||
      fillterFolder[0]?.strains.length > 0
        ? [...fillterFolder[0].products, ...fillterFolder[0].strains]
        : [];

    return combinedArray;
  }, [fillterFolder]); // Assuming fillterFolder should be the dependency

  // fillter folder product
  useMemo(() => {
    var filterFolder = folder?.filter(
      (folder) => folder._id === Data.selectedFolder
    );
    setFillterFolder(filterFolder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Data?.selectedFolder]);

  // consumtion method
  const addConsumtion = async (method) => {
    try {
      const res = await AddUserData({ userMethods: method });
      if (res?.status === 200) {
        setMethod((prev) => [method, ...prev]);
        setaddMethod('');
        enqueueSnackbar(res?.data?.message, { variant: 'success' });
      }
      if (res?.response?.status === 400) {
        enqueueSnackbar(res?.response?.data?.message, { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };
  const addReason = async (method) => {
    try {
      const res = await AddUserData({ userReason: method });
      if (res?.status === 200) {
        setisUpdate(!isUpdate);
        setAddNewReason(''); // Reset the method input field
        enqueueSnackbar(res?.data?.message, { variant: 'success' });
        if (Data.useReason.length < 4) {
          handleSelectHelpsWith(method);
        }
      }
      if (res?.response?.status === 400) {
        enqueueSnackbar(res?.response?.data?.message, { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };
  const handleSelectHelpsWith = (selectedCondition) => {
    setData((prevData) => {
      let updatedUseReason;
      if (
        prevData.useReason.some((item) => item.reason === selectedCondition)
      ) {
        // Reason already exists, remove it (checkbox deselected)
        updatedUseReason = prevData.useReason.filter(
          (item) => item.reason !== selectedCondition
        );
      } else if (prevData.useReason.length < 4) {
        // Add new reason with default level (checkbox selected)
        updatedUseReason = [
          ...prevData.useReason,
          { reason: selectedCondition, startLevel: 1 },
        ];
      } else {
        // Do not add new reasons if there are already 4 selected
        return prevData;
      }

      return {
        ...prevData,
        useReason: updatedUseReason,
      };
    });
  };

  const handleStartSession = () => {
    if (!sessionStarted) {
      const startTime = new Date().toISOString();
      setData((pre) => ({ ...pre, sessionStartTime: startTime }));
      dispatch(startSession({ ...Data, sessionStartTime: startTime }));
      setSessionStarted(true);
    }
  };

  const handleEndSession = async () => {
    dispatch(setLoading(true));
    try {
      const endTimeLocal = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"); // Get the current local time
      const sessionData = {
        ...Data,
        sessionEndTime: endTimeLocal,
      };
      const {
        strain,
        userAdded,
        selectedProduct,
        selectedItem,
        selectedBussines,
        selectedFolder,
        ...rest
      } = sessionData;

      switch (Data.selectedItem) {
        case 'Strain':
          await createSession({ selectedItem, strain, ...rest });
          break;
        case 'Product':
          await createSession({
            selectedItem,
            product: selectedProduct,
            selectedBussines,
            ...rest,
          });
          break;
        case 'Stash':
          await createSession({
            selectedFolder,
            stash: selectedProduct,
            selectedItem,
            ...rest,
          });
          break;
        case 'UserAdded':
          await createSession({
            selectedItem,
            userAdded: userAdded,
            selectedProduct,
            ...rest,
          });
          break;
        default:
          throw new Error('Invalid selection type');
      }

      enqueueSnackbar('Session successfully added', { variant: 'success' });
      navigate('/sessions');
    } catch (err) {
      enqueueSnackbar(`Failed to add session: ${err.message}`, {
        variant: 'error',
      });
    } finally {
      dispatch(setLoading(false));
      dispatch(endSession());
    }
  };

  const handleRangeChange1 = (event, condition) => {
    const newLevel = parseInt(event.target.value);
    setData((prevData) => {
      return {
        ...prevData,
        useReason: prevData.useReason?.map((item) =>
          item.reason === condition ? { ...item, startLevel: newLevel } : item
        ),
      };
    });
  };

  const handleRangeChange2 = (e, index) => {
    const newValue = parseInt(e.target.value, 10);
    setData((prevState) => ({
      ...prevState,
      useReason: prevState.useReason?.map((item, i) =>
        i === index ? { ...item, endLevel: newValue } : item
      ),
    }));
  };

  const handleEditEffect = (update) => {
    if (update?.type === 'effect') {
      setOldValue(update.newValue);
      setCustomEffectInput(update.newValue);
    } else {
      setCustomFlavorInput(update.newValue);
      setOldValue(update.newValue);
    }
  };

  const handleEditEffectUpdate = async (data) => {
    try {
      const res = await updateUserItem({
        [data.type]: { oldValue: data.oldValue, newValue: data.newValue },
      });

      if (res) {
        setCustomEffectInput('');
        setCustomFlavorInput('');
        setOldValue('');
        setisUpdate(!isUpdate);
        enqueueSnackbar(res?.message, { variant: 'success' });
      }
    } catch (error) {
      setOldValue('');
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };

  const handleDeleteEffect = async (effect) => {
    try {
      const res = await deleteUserItem(effect);
      if (res) {
        setisUpdate(!isUpdate);
        enqueueSnackbar(res?.message, { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };

  return (
    <div className="mb-20 xl:w-[60%] md:w-[80%] w-full mx-auto px-4 ">
      <div className="text-[#7cdb54] text-sm  underline  flex items-center">
        <IoArrowBack
          size="1.2em"
          style={{ marginRight: '4px', marginTop: '1px' }}
        />
        <button onClick={() => navigate(-1)} type="button">
          Back to Sessions
        </button>
      </div>
      {/* review type */}
      <div className="flex justify-center items-center mt-4">
        <div className="flex justify-between items-center gap-3 w-40">
          {' '}
          {/* Adjust width as needed */}
          <span className="text-sm font-medium text-gray-300">Public</span>
          <label className="inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              value={Data.reviewType}
              className="sr-only peer"
              checked={Data.reviewType === 'Private' ? true : false}
              onChange={(e) => {
                e.target.checked
                  ? setData({ ...Data, reviewType: 'Private' })
                  : setData({ ...Data, reviewType: 'Public' });
              }}
            />
            <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
          </label>
          <span className="text-sm font-medium text-mary-theme">Private</span>
        </div>
      </div>

      <div className="py-4">
        <h4 className=" font-bold text-lg text-left -mt-2  text-white">
          What are you consuming?
        </h4>

        <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4  gap-3">
          <Select
            HelpfullText="Select"
            value={Data?.selectedItem}
            options={[
              { name: 'Strain', value: 'Strain' },
              { name: 'Product', value: 'Product' },
              { name: 'Stash', value: 'Stash' },
              { name: 'User Added', value: 'UserAdded' },
              { name: 'Add New', value: 'Add New' },
            ]}
            handleChange={(e) => {
              setData({
                ...Data,
                selectedItem: e.target.value,
                selectedBussines: '',
              });
            }}
          />
          {Data?.selectedItem === 'UserAdded' && (
            <Select
              HelpfullText="Select Product or strain "
              value={Data?.userAdded}
              options={userAddedItem?.map((item) => {
                return { name: item?.title, value: item?._id };
              })}
              handleChange={(e) => {
                setData({
                  ...Data,
                  userAdded: e.target.value,
                });
              }}
            />
          )}

          {Data?.selectedItem === 'Strain' && (
            <Select
              HelpfullText="Select Strain "
              value={Data?.strain}
              options={allStrain?.map((item) => {
                return { name: item?.title, value: item?._id };
              })}
              handleChange={(e) => {
                setData({
                  ...Data,
                  strain: e.target.value,
                  selectedProduct: '',
                });
              }}
            />
          )}
          {Data?.selectedItem === 'Product' && (
            <Select
              HelpfullText="Select Bussiness"
              value={Data?.selectedBussines}
              options={allBrands?.map((item) => {
                return { name: item?.title, value: item?._id };
              })}
              handleChange={(e) => {
                setData({
                  ...Data,
                  selectedBussines: e.target.value,
                });
              }}
            />
          )}
          {Data?.selectedItem === 'Product' && Data.selectedBussines !== '' ? (
            <Select
              HelpfullText="Select Product"
              value={Data?.selectedProduct}
              options={allProducts?.map((item) => {
                return { name: item?.title, value: item?._id };
              })}
              handleChange={(e) => {
                setData({
                  ...Data,
                  selectedProduct: e.target.value,
                  strain: '',
                });
              }}
            />
          ) : (
            <></>
          )}

          {Data?.selectedItem === 'Stash' ? (
            <Select
              HelpfullText="Select Stash Folder"
              value={Data?.selectedFolder}
              options={folder?.map((item) => {
                const itemCount = item.strains
                  ? item?.strains?.length + item?.products.length
                  : 0;
                return {
                  name: `${item?.name} (${itemCount} items)`,
                  value: item?._id,
                };
              })}
              handleChange={(e) => {
                setData({
                  ...Data,
                  selectedFolder: e.target.value,
                });
              }}
            />
          ) : (
            <></>
          )}
          {Data?.selectedItem === 'Stash' && Data?.selectedFolder !== '' && (
            <Select
              HelpfullText="Select Product from Folder"
              value={Data?.selectedProduct}
              options={folderOptions?.map((item) => {
                return { name: item?.title, value: item?._id };
              })}
              handleChange={(e) => {
                setData({
                  ...Data,
                  selectedProduct: e.target.value,
                });
              }}
            />
          )}

          {fillterFolder[0]?.items?.length === 0 && (
            <>
              <p className=" text-red-700 ">
                Product not found in selected strain please select another stash
              </p>
            </>
          )}

          <div>
            {Data?.selectedItem === 'Add New' && (
              <AddCustomProductModal
                isOpen={Data?.selectedItem === 'Add New'}
                onRequestClose={() =>
                  setData({
                    ...Data,
                    selectedItem: '',
                  })
                }
                onProductAdded={handleProductAdded}
              />
            )}
          </div>
        </div>
      </div>
      <div className="flex items-center ">
        <h2 className="text-white text-lg ml-2 lg:font-bold">
          {currentStrain?.title}
        </h2>
      </div>
      <div>
        <div className="bg-[#1d1d1d] p-4 shadow-md  rounded ">
          <p className=" font-bold text-lg text-left  text-white">
            How are you consuming?
          </p>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full mt-4 ">
            {method?.map((item, index) => (
              <label
                key={index}
                className="flex items-center space-x-2 text-white"
              >
                <input
                  type="checkbox"
                  name="consuming method"
                  value={item}
                  checked={Data.selectedMethod.includes(item)}
                  onChange={() => handleMethodChange(item)}
                  className="custom-checkbox w-5 h-5"
                />
                <span>{item}</span>
              </label>
            ))}
          </div>
          <div className="mt-4 flex justify-center items-center">
            <input
              type="text"
              value={addMethod}
              onChange={(e) => {
                setaddMethod(e.target.value);
              }}
              className="bg-white p-3 lg:p-4 text-black rounded-l-md focus:outline-none"
              placeholder="Add consumption method"
            />
            <button
              onClick={() => {
                addMethod !== '' &&
                  addConsumtion(
                    addMethod?.replace(/^./, addMethod[0]?.toUpperCase())
                  );
                handleMethodChange(
                  addMethod !== '' &&
                    addMethod?.replace(/^./, addMethod[0]?.toUpperCase())
                );
              }}
              className="bg-[#62AF41] hover:bg-[#4c8633] p-4 lg:p-5 rounded-r-md text-white"
            >
              <FaPlus />
            </button>
          </div>
        </div>
      </div>

      <div className=" py-4">
        <p className=" font-bold text-lg text-left  text-white">
          How much are you consuming?
        </p>
        <div className="grid grid-cols-4">
          <input
            className=" col-span-3 rounded-bl-lg bg-white p-3 lg:p-4 text-black rounded-l-md focus:outline-none "
            type="number"
            value={Data?.consumingQty}
            required
            placeholder="Enter quantity"
            onChange={(e) =>
              setData((prev) => ({
                ...prev,
                consumingQty:
                  e.target.value > 0 ? Number(e.target.value) : null,
              }))
            }
          />
          <select
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-e-lg border-s-gray-100 dark:border-s-gray-700 border-s-2 block w-full p-2.5 focus:outline-none   "
            name="consumingQty"
            placeholder="Enter consuming quantity"
            onChange={(e) =>
              setData((prev) => ({
                ...prev,
                consumingUnit: e.target.value,
              }))
            }
          >
            <option value="Gram">Gram</option>
            <option value="Milligram">Milligram</option>
            <option value="Dab">Dab</option>
            <option value="Ounce">Ounce</option>
          </select>
        </div>
      </div>

      {/* Helps With */}
      <AccordionSection
        title="What is your reason for use?"
        defaultOpen={false}
        count={Data?.useReason.length}
      >
        {/* Render checkboxes for each condition */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
          {[...(userAddedData?.userReason || []), ...conditionsList]
            .slice(
              0,
              isExpandedHelpWith
                ? conditionsList?.length + userAddedData?.useReason?.length ===
                  'undefined'
                  ? 0
                  : userAddedData?.useReason?.length
                : displayItemCount
            )
            .map((condition, index) => (
              <div
                key={index}
                className="flex justify-between items-center gap-12"
              >
                <CheckboxItem
                  itemName={condition}
                  isMaxSelected={Data.useReason.length >= 4} // Assuming you want to limit the number of selectable items to 4
                  onSelect={() => handleSelectHelpsWith(condition)}
                  isSelected={Data.useReason.some(
                    (item) => item.reason === condition
                  )}
                />
                {Data.useReason.some((item) => item.reason === condition) && (
                  <div className="w-[50%] flex flex-col justify-center items-center mt-2">
                    <input
                      className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                      type="range"
                      value={
                        Data.useReason.find((item) => item.reason === condition)
                          ?.startLevel || 1
                      }
                      min="1"
                      max="10"
                      onChange={(e) => handleRangeChange1(e, condition)}
                    />
                    <div className="w-full flex justify-between items-center">
                      {Array.from({ length: 10 })?.map((_, index) => (
                        <span
                          className=" ml-1.5 text-sm text-gray-500 dark:text-gray-400"
                          key={index}
                        >
                          {index + 1}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>
        {/* Expand/Collapse Button */}
        <button
          onClick={toggleExpandForHelpsWith}
          className="mt-2 text-[#62AF41]"
        >
          {isExpandedHelpWith ? 'Collapse all' : 'Expand all'}
        </button>
        <div className="mt-4 flex justify-center items-center">
          <input
            type="text"
            value={addNewReason}
            onChange={(e) => {
              setAddNewReason(e.target.value);
            }}
            className="bg-white p-3 lg:p-4 text-black rounded-l-md focus:outline-none"
            placeholder="Add reason"
          />
          <button
            onClick={() => {
              const newMethod = addNewReason?.replace(
                /^./,
                addNewReason[0].toUpperCase()
              );
              if (newMethod !== '') {
                addReason(newMethod);
              }
            }}
            className="bg-[#62AF41] hover:bg-[#4c8633] p-4 lg:p-5 rounded-r-md text-white"
          >
            <FaPlus />
          </button>
        </div>
      </AccordionSection>

      {/* New Consumed With Section */}
      <div className="bg-[#1d1d1d] p-4 shadow-md rounded mt-4">
        <p className="font-bold text-lg text-left text-white">
          Who are you consuming with?
        </p>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full mt-4">
          {consumedWithOptions.map((option, index) => (
            <label
              key={index}
              className="flex items-center space-x-2 text-white"
            >
              <input
                type="checkbox"
                name="consumedWith"
                checked={Data.consumingWith?.includes(option)}
                onChange={() => handleConsumedWith(option)}
                className="custom-checkbox w-5 h-5"
              />
              <span>{option}</span>
            </label>
          ))}
        </div>
        <div className="mt-4 flex justify-center items-center">
          <input
            type="text"
            value={addNewConsumedWith}
            onChange={(e) => setAddNewConsumedWith(e.target.value)}
            className="bg-white p-3 lg:p-4 text-black rounded-l-md focus:outline-none"
            placeholder="Add consumed with "
          />
          <button
            onClick={() =>
              addNewConsumedWith !== '' ? addConsumedWithOption() : undefined
            }
            className="bg-[#62AF41] hover:bg-[#4c8633] p-4 lg:p-5 rounded-r-md text-white"
          >
            <FaPlus />
          </button>
        </div>
      </div>

      {/* New Places of Consumption Section */}
      <div className="bg-[#1d1d1d] p-4 shadow-md rounded mt-4">
        <p className="font-bold text-lg text-left text-white">
          Where are you consuming?
        </p>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full mt-4">
          {placesOptions.map((option, index) => (
            <label
              key={index}
              className="flex items-center space-x-2 text-white"
            >
              <input
                type="checkbox"
                name="placesOfConsumption"
                checked={Data?.consumingPlace?.includes(option)}
                onChange={() => handlePlaceChange(option)}
                className="custom-checkbox w-5 h-5"
              />
              <span>{option}</span>
            </label>
          ))}
        </div>
        <div className="mt-4 flex justify-center items-center">
          <input
            type="text"
            value={addNewPlace}
            onChange={(e) => setAddNewPlace(e.target.value)}
            className="bg-white p-3 lg:p-4 text-black rounded-l-md focus:outline-none"
            placeholder="Add new place"
          />
          <button
            onClick={() => {
              addNewPlace !== '' && addPlaceOfConsumption();
            }}
            className="bg-[#62AF41] hover:bg-[#4c8633] p-4 lg:p-5 rounded-r-md text-white"
          >
            <FaPlus />
          </button>
        </div>
      </div>

      <div className="my-10">
        <button
          onClick={() => !sessionStarted && handleStartSession()}
          type="Button"
          className="w-full rounded-full py-2 text-white text-xl font-bold bg-[#62AF41] hover:bg-[#4c8633]"
        >
          {sessionStarted
            ? `Your session duration : ${timer}`
            : 'Start Session'}
        </button>
      </div>

      {sessionStarted ? (
        <div>
          <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2  ">
            {Data?.useReason.length && (
              <>
                <p className=" font-bold text-lg text-left  text-white">
                  How were these reasons affected?
                </p>
                {Data?.useReason?.map((item, index) => (
                  <div
                    key={index}
                    className="flex justify-between md:justify-start  items-center gap-8"
                  >
                    <div className=" text-white">
                      <h1>{item?.reason}</h1>
                    </div>
                    <div className="w-full">
                      <CustomMultiRangeSlider
                        value1={item?.startLevel}
                        value2={item?.endLevel}
                        min={0}
                        max={10}
                        readOnly={true}
                        onChange2={(e) => handleRangeChange2(e, index)}
                      />
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>

          {/* Accordion for 'Which effects did you feel?' */}
          <div className="">
            <AccordionSection
              title="Which effects did you feel?"
              defaultOpen={false}
              count={selectedEffects.length}
            >
              <div className="grid grid-cols-3 lg:grid-cols-6 gap-4">
                {effectsList?.map((effect) => (
                  <EffectItem
                    key={effect.name}
                    effectName={effect.name}
                    effectIcon={effect.icon}
                    isMaxSelected={selectedEffects.length >= 4}
                    onSelect={handleSelectEffect}
                    isSelected={selectedEffects.includes(effect.name)}
                  />
                ))}
                {[
                  ...(userAddedData?.userFeelings?.map((item) => ({
                    name: item,
                    icon: FaUserEdit,
                  })) || []),
                ]?.map((effect, index) => (
                  <EffectItem
                    key={`custom-${index}`}
                    effectName={effect.name}
                    effectIcon={effect.icon}
                    isMaxSelected={selectedEffects.length >= 4}
                    onSelect={handleSelectEffect}
                    isSelected={selectedEffects.includes(effect.name)}
                    isEditable={true}
                    handleEdit={(effect) =>
                      handleEditEffect({ type: 'effect', newValue: effect })
                    }
                    handleDelete={(effect) =>
                      handleDeleteEffect({ userFeelings: effect })
                    }
                  />
                ))}
              </div>
              <div className="mt-4 flex justify-center items-center">
                <input
                  type="text"
                  value={customEffectInput}
                  onChange={(e) => setCustomEffectInput(e.target.value)}
                  className="bg-[#4b4646] p-3 lg:p-4 text-white rounded-l-md focus:outline-none"
                  placeholder="Add effect"
                />
                <button
                  onClick={
                    oldValue
                      ? () =>
                          handleEditEffectUpdate({
                            type: 'userFeelings',
                            newValue: customEffectInput,
                            oldValue: oldValue,
                          })
                      : handleAddCustomEffect
                  }
                  className="bg-[#62AF41] hover:bg-[#4c8633] p-4 lg:p-5 rounded-r-md text-white"
                >
                  <FaPlus />
                </button>
              </div>
            </AccordionSection>

            {/* Accordion for 'Describe the flavor & smell?' */}
            <AccordionSection
              title="Describe the flavor & smell?"
              defaultOpen={false}
              count={flavorAndSmell.length}
            >
              <div className="grid grid-cols-3 lg:grid-cols-6 gap-4">
                {flavorsList
                  .slice(
                    0,
                    isExpandedFlavor
                      ? flavorsList.length
                      : window.innerWidth >= 1024
                      ? 18
                      : 7
                  )
                  ?.map((flavor) => (
                    <FlavourItem
                      key={flavor.name}
                      flavorName={flavor.name}
                      flavorIcon={flavor.icon}
                      isMaxSelected={flavorAndSmell.length >= 4}
                      onSelect={handleSelectFlavor}
                      isSelected={flavorAndSmell.includes(flavor.name)}
                    />
                  ))}
                {[
                  ...(userAddedData?.userFlavours?.map((item) => ({
                    name: item,
                    icon: FaUserEdit,
                  })) || []),
                ]?.map((flavor, index) => (
                  <FlavourItem
                    key={`custom-${index}`}
                    flavorName={flavor.name}
                    flavorIcon={flavor.icon}
                    isMaxSelected={flavorAndSmell.length >= 4}
                    onSelect={handleSelectFlavor}
                    isSelected={flavorAndSmell.includes(flavor.name)}
                    isEditable={true}
                    handleEdit={(flavor) =>
                      handleEditEffect({ type: 'flavor', newValue: flavor })
                    }
                    handleDelete={(effect) =>
                      handleDeleteEffect({ userFlavours: effect })
                    }
                  />
                ))}
              </div>
              <div className="mt-4 flex justify-center items-center">
                <input
                  type="text"
                  value={customFlavorInput}
                  onChange={(e) => setCustomFlavorInput(e.target.value)}
                  className="bg-[#4b4646] p-3 lg:p-4 text-white rounded-l-md focus:outline-none"
                  placeholder="Add flavor"
                />
                <button
                  onClick={
                    oldValue
                      ? () =>
                          handleEditEffectUpdate({
                            type: 'userFlavours',
                            newValue: customFlavorInput,
                            oldValue: oldValue,
                          })
                      : handleAddCustomFlavor
                  }
                  className="bg-[#62AF41] hover:bg-[#4c8633] p-4 lg:p-5 rounded-r-md text-white"
                >
                  <FaPlus />
                </button>
              </div>
              <button
                onClick={toggleExpandForFlavor}
                className="mt-2 text-[#62AF41]"
              >
                {isExpandedFlavor ? 'Collapse all' : 'Expand all'}
              </button>
            </AccordionSection>

            <hr className="border-t border-gray-500" />
            {/* range slider */}
            <div className="relative mb-6">
              <label
                htmlFor="labels-range-input"
                className=" text-white text-lg font-bold"
              >
                What was the overall potency
              </label>
              <input
                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                id="labels-range-input"
                type="range"
                value={Data.intendedUse}
                min="1"
                max="10"
                onChange={(e) => {
                  setData({ ...Data, intendedUse: Number(e.target.value) });
                }}
              />
              <div className="flex justify-between items-center">
                {Array.from({ length: 10 }).map((_, index) => (
                  <span
                    key={index}
                    className=" ml-1.5 text-sm text-gray-500 dark:text-gray-400  start-0 -bottom-6"
                  >
                    {index + 1}
                  </span>
                ))}
              </div>
            </div>
            <div className=" mt-6 shadow-md rounded">
              <h2 className="text-white font-bold text-lg mb-2">
                Any additional thoughts?
              </h2>
              <p className="text-gray-300 text-sm mb-4">
                Your insights contribute to our community's understanding and
                connection with this remarkable plant.
              </p>
              <textarea
                className="bg-[#ffffff] w-full p-2 lg:p-4 lg:h-52 text-black border lg:border-b-2 rounded-lg focus:outline-none lg:border-black lg:mb-4"
                rows="4"
                placeholder="Share your experience"
                value={Data.additionalThoughts}
                onChange={(e) =>
                  setData({ ...Data, additionalThoughts: e.target.value })
                }
              />
            </div>

            <div className=" my-10 space-y-4">
              <button
                onClick={handleEndSession}
                type="Button"
                className={`w-full rounded-full py-2 text-white text-xl font-bold bg-[#62AF41] hover:bg-[#4c8633]`}
              >
                {loading ? 'Loading...' : 'End Sessions'}
              </button>
              <div className=" text-gray-400 text-center">
                <span>Do you want to cancel this session?</span>
                <span
                  onClick={() => {
                    dispatch(endSession());
                    enqueueSnackbar('session secussfuly cancel', {
                      variant: 'success',
                    });
                    navigate('/sessions');
                  }}
                  className=" text-center underline underline-offset-3 ml-2 font-semibold cursor-pointer text-white"
                >
                  Cancel
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SessionsReview;
